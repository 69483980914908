import { useContext, useEffect } from 'react';
import { differenceInDays } from 'date-fns';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';
import {
	ExperienceTrackerContext,
	ONBOARDING_PERSONALIZED_TEMPLATES_V2,
} from '@confluence/experience-tracker';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useUserByLicensedProducts } from '@confluence/onboarding-helpers/entry-points/hooks/useUserByLicensedProducts';
import { useIsEstablishedUserTraitName } from '@confluence/onboarding-helpers/entry-points/hooks/useIsEstablishedUserTraitName';
import { SPAViewContext } from '@confluence/spa-view-context';

import { PERSONALIZED_TEMPLATES_V2_ONBOARDING_STATE_KEYS } from '../personalizedTemplatesV2Constants';

import { useGetTeamAndSubanchor } from './useGetTeamAndSubanchor';

const GetPersonalizationQuery = gql`
	query useIsEligibleForPersonalizedTemplatesV2UserPersonalizationQuery($userId: String!)
	@experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;

export const useIsEligibleForPersonalizedTemplatesV2 = () => {
	const { userId } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const isEstablishedUserTraitName = useIsEstablishedUserTraitName();

	const experienceTracker = useContext(ExperienceTrackerContext);
	useEffect(() => {
		if (fg('cc-onboarding-personalized-templates-v2-eligible')) {
			experienceTracker.start({
				name: ONBOARDING_PERSONALIZED_TEMPLATES_V2,
			});
		}
	}, [experienceTracker]);

	// First check if they are even a founding admin
	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN,
			...Object.values(PERSONALIZED_TEMPLATES_V2_ONBOARDING_STATE_KEYS),
		],
		!isSiteAdmin || !fg('cc-onboarding-personalized-templates-v2-eligible'),
	);

	// Check established user at the same time as we don't need to wait
	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery(GetPersonalizationQuery, {
		skip: !isSiteAdmin || !fg('cc-onboarding-personalized-templates-v2-eligible'),
		variables: {
			userId: userId!,
		},
	});

	const { isFoundingAdmin, takeoverSeenAt, hasDismissedTakeover } =
		deserializeState(onboardingStateData);

	const isInOnboardingPeriod =
		Boolean(takeoverSeenAt) && differenceInDays(new Date(), new Date(takeoverSeenAt)) <= 14;
	const takeoverExpired =
		Boolean(takeoverSeenAt) && differenceInDays(new Date(), new Date(takeoverSeenAt)) > 14;

	const ineligibleForTakeover = !isFoundingAdmin || takeoverExpired || hasDismissedTakeover;

	const attributes =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	// if we skip querying for personalization,
	// we can assume that user should be establishedUser and skip over onboarding
	const isEstablishedUser =
		ineligibleForTakeover ||
		attributes.find(
			(attr: { name: string; value: string }) => attr?.name === isEstablishedUserTraitName,
		)?.value === 'true';

	const isEstablishedUserReady = !userPersonalizationQueryLoading && !userPersonalizationQueryError;

	const isNotEstablishedUser = isEstablishedUserReady && !isEstablishedUser;

	const potentiallyEligibleUserForPTV2 =
		isFoundingAdmin && !takeoverExpired && !hasDismissedTakeover && isNotEstablishedUser;
	// If both founding admin + !established user, then we can check if they are a land founding admin

	const {
		isLandUser,
		error: userTypeError,
		loading: userTypeLoading,
	} = useUserByLicensedProducts({
		skip:
			!potentiallyEligibleUserForPTV2 || !fg('cc-onboarding-personalized-templates-v2-eligible'),
	});

	const skipTeamAndSubanchorQueries =
		!isLandUser ||
		!potentiallyEligibleUserForPTV2 ||
		!fg('cc-onboarding-personalized-templates-v2-eligible');

	const {
		teamType,
		personalSpaceKey,
		subAnchor,
		personalSpaceKeyAndTeamLoading,
		personalSpaceKeyAndTeamError,
		subAnchorLoading,
		subAnchorError,
	} = useGetTeamAndSubanchor({
		skip: skipTeamAndSubanchorQueries,
	});

	/**
	 * 1. If they have seen it, then they are simply eligible for the takeover.
	 * 2. If they haven't seen it yet,  then we need to check the other eligibility conditions
	 * 		to determine if we should set `takeoverSeenAt` later -> show the takeover.
	 *   a. They need to be not established
	 *   b. They need to be a founding admin
	 *   c. They need to have either a teamType or subanchor
	 *   d. They need to have a personal space
	 */
	const isGenerallyEligible =
		takeoverSeenAt !== undefined ||
		Boolean(
			isNotEstablishedUser &&
				isFoundingAdmin &&
				(Boolean(subAnchor) || Boolean(teamType)) &&
				Boolean(personalSpaceKey),
		);

	if (
		subAnchorLoading ||
		personalSpaceKeyAndTeamLoading ||
		userTypeLoading ||
		onboardingStateLoading ||
		userPersonalizationQueryLoading
	) {
		return {
			isEligibleForLand: false,
			loading: true,
			isVisible: false,
		};
	}

	if (
		userTypeError ||
		onboardingStateError ||
		userPersonalizationQueryError ||
		subAnchorError ||
		personalSpaceKeyAndTeamError
	) {
		experienceTracker.stopOnError({
			name: ONBOARDING_PERSONALIZED_TEMPLATES_V2,
			error: (userTypeError ||
				onboardingStateError ||
				userPersonalizationQueryError ||
				subAnchorError ||
				personalSpaceKeyAndTeamError)!,
		});
	}

	return {
		isEligibleForLand: isLandUser && isGenerallyEligible,
		loading: false,
		teamType,
		subAnchor,
		personalSpaceKey,
		isVisible:
			isLandUser &&
			isGenerallyEligible &&
			(takeoverSeenAt === undefined || isInOnboardingPeriod) &&
			!hasDismissedTakeover,
	};
};
